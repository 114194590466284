.block.slider {
    flex-direction: row;
    gap: 3vmin 0;
    margin: 3vmin 0 5vmin;

    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: scale(180%, 115%);
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 50%,
            rgba(255, 255, 255, 0) 100%
        );
        pointer-events: none;
    }

    &.block_preview {
        .glide--slides {
            gap: 2vmin;
        }
    }
}

.slider--slide {
    display: flex;
    flex-direction: column;
}

.slider-image--thumbnail {
    margin-top: 2vmin;
}

.slider--footer {
    display: block;
    background: $charcoal;
    color: $white;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1.5px;

    padding: 14px 0;
}

.block.slider {
    .glide {
        * {
            box-sizing: inherit;
        }
    }

    .glide--slides {
        display: grid;
        grid-template-areas: 'slide';

        > li {
            flex-shrink: 0;
        }
    }

    .glide--slide {
        position: relative;
        opacity: 0;
        transition: opacity 0.8s ease-in-out;
        grid-area: slide;
    }

    .glide__slide--active {
        z-index: 1;
        opacity: 1;
    }

    .glide--track {
        overflow: hidden;
    }

    .glide--slides {
        margin: 0;
        padding: 0;
        list-style: none;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        touch-action: pan-Y;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        flex-wrap: nowrap;
        will-change: transform;
    }

    .glide--slide {
        position: relative;
        overflow: hidden;
        margin: 0 !important;
        padding: 0;
        flex-shrink: 0;
        white-space: normal;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
    }

    .glide--arrows {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: calc(100% + (70px * 2));
        top: 45px;
        left: -70px;

        @include media($large-desktop-down) {
            display: none;
        }
    }

    .glide--arrow {
        border: none;
        // background-color: $dark-gray;

        &:hover {
            background-color: $gray;
        }
    }
}
