.cover-slider {
    padding: 0;

    .glide--slides {
        display: block;
        padding: 0;
        margin: 0;
        transform: none !important;
        width: auto !important;
        display: grid;
        grid-template-areas: 'slide';
    }

    .glide--slide {
        display: block;
        position: relative;
        opacity: 0;
        transition: opacity 1.4s ease-in-out;
        grid-area: slide;
    }
    .glide__slide--active {
        z-index: 1;
        opacity: 1;
    }

    .glide--arrows-container {
        @include container;
        display: flex;
        position: absolute;
        bottom: 5vmin;
        left: 0;
        width: 100%;

        padding: 0;
        z-index: 50;

        @include media($tablet-down) {
        }

        @include media($small-mobile-down) {
        }

        @include media($desktop-down) {
            align-items: flex-end;
        }
    }

    .glide--arrows {
        @include inner-center;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    .glide--arrow {
        background: none;
        border: none;

        @include media($small-mobile-down) {
            padding: 1rem;
        }

        svg {
            width: 30px;
            @include media($small-mobile-down) {
                width: 30px;
            }
        }
    }

    // .glide--arrow--left {
    //     position: relative;
    //     left: -0.45rem;
    // }
}

.block.large-cover {
    width: 100%;
    height: 65vmin;
    background-color: $off-white;
    overflow: hidden;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0%;

    @include media($tablet-down) {
        background-position: 62% 50%;
        height: 160vmin;
    }

    .home & {
        // height: 65vmin;

        // @include media($tablet-down) {
        //     height: 160vmin;
        //     background-position: 62% 50%;

        //     .large-cover--content {
        //         padding-right: 35vmin;
        //         padding-bottom: 20vmin;
        //         padding-left: 4vmin;

        //         > p {
        //             font-size: 14px;
        //         }

        //         .large-cover--button {
        //             font-size: 14px;
        //             letter-spacing: 1.5px;
        //         }
        //     }
        // }
    }

    &.block_preview {
        height: 40vmin;
    }
}

.large-cover--container {
    @include inner-center;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    @include media($tablet-down) {
        .large-cover--content {
            padding-right: 25vmin;
            padding-bottom: 20vmin;
            padding-left: 4vmin;
            > p {
                font-size: 14px;
            }
        }

        .large-cover--button {
            font-size: 14px;
            letter-spacing: 1.5px;
        }
    }
}

.large-cover--content {
    position: relative;
    max-width: 455px;

    z-index: 20;

    color: $white;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $white;
    }

    > p {
        font-size: 20px;
        line-height: 1.2 !important;
    }
}

.large-cover--title {
    font-size: 5vmin;
    font-weight: 300;
    letter-spacing: 2px;
    line-height: 1;
    color: $white;

    @include media($tablet-down) {
        font-size: 30px;
    }
}

.large-cover--button {
    display: inline-block;
    // color: $white;

    // border: 1px solid $white;
    // padding: 10px 15px;

    margin-top: 20px;
}
