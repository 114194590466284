.block.pricing-grid {
    @include media($desktop-down) {
    }

    .pricing-grid--item {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $dark-gray;
        padding: 12px 0;

        &:first-child {
            border-top: 1px solid $dark-gray;
        }

        @include media($desktop-down) {
            flex-direction: column;
            padding: 20px 10px;
            font-size: 14px;
        }
    }

    .pricing-grid--treatment {
        display: block;
        margin: 0;
        flex-basis: 50%;
        font-size: 17px;
        font-weight: 400;

        @include media($desktop-down) {
            margin-bottom: 7px;
        }
    }

    .pricing-grid--price {
        display: block;
        margin: 0;
        flex-basis: 50%;
        font-size: 17px;
        font-weight: 400;
        line-height: 1.8;
    }
}
