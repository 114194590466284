.block.text-title {
    .text-title--title {
        display: inline-block;
    }

    .text-title--subtitle {
        display: inline-block;
        font-size: 3vmin;
        line-height: 1.2;
        margin-bottom: 0;

        @include media($tablet-down) {
            font-size: 18px;
        }

        &::before {
            content: '';
            display: inline-block;
            height: 5.5vmin;
            border-left: 1px solid $black;
            margin: 0 1.2vmin;
        }
    }
}
