.block.before-and-after {
    display: flex;
    flex-direction: column;
    gap: 3vmin 0;
}

.before-and-after--footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.before-and-after--view-all {
    &.active {
        .icon-button--icon {
            rotate: 180deg;
        }
    }
}

.gallery {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    justify-content: start;
    justify-items: start;

    column-gap: 3vmin;
    row-gap: 3vmin;

    overflow: hidden;

    @include media($tablet-down) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include media($mobile-down) {
        grid-template-columns: 1fr 1fr;
    }

    &.gallery-hidden {
        height: 0;
    }
}

.gallery-image {
    transition: all ease-in-out 300ms;
    padding-top: 0;
    position: relative;
    overflow: hidden;
    transition: all ease-in-out 300ms;

    cursor: pointer;

    filter: grayscale(100%);

    &:hover {
        filter: grayscale(0%);
    }

    &.block_preview {
        .gallery-image--icon {
            display: none !important;
        }
    }
}
