.block.icon-button {
    padding: 10px 15px 10px 0;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    transition: all ease-in-out 300ms;
    white-space: nowrap;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1.5px;

    &.border-light {
        padding: 10px 15px;
        border: 2px solid $white;
        text-transform: uppercase;
        color: $white;

        &:hover {
            border-color: $mute-pink;
            color: $mute-pink;
        }
    }

    &.border-dark {
        padding: 10px 15px;
        border: 2px solid $black;
        text-transform: uppercase;
        color: $black;

        &:hover {
            border-color: $mute-pink;
            color: $mute-pink;
        }
    }

    &.align-left {
        align-self: flex-start;
    }
    &.align-center {
        align-self: center;
    }
    &.align-right {
        align-self: flex-end;
    }
    &.align-none {
        align-self: inherit;
    }
}

.icon-button--text {
    display: block;
    letter-spacing: 1.5px;
}

img.icon-button--icon {
    display: block;
    max-width: 30px;
    margin-right: 22px;
}
