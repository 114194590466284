.block.image-hover-button {
    display: inline-flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-weight: 400;
    line-height: 1.1;

    background: $off-white;

    overflow: hidden;

    &:hover,
    &.active {
        .image-hover-button--image {
            filter: grayscale(0%);
            transform: scale(1.3);
            opacity: 0.8;
        }
    }
}

.image-hover-button--text {
    display: flex;
    position: absolute;
    left: 0;
    bottom: 2vmin;
    height: 16vmin;
    width: 100%;

    padding: 4vmin 3vmin;

    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 20%,
        rgba(255, 255, 255, 0) 100%
    );

    font-size: 2.4vmin;
    white-space: wrap;
    color: $dark-charcoal;

    @include media($tablet-down) {
        font-size: 16px;
        height: 18vmin;
    }

    &::before {
        position: absolute;
        left: 0;
        top: -49px;
        content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEEAAAAxCAMAAACyCzS2AAAAdVBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////li2ZAAAAAJnRSTlMAbfm2FDQP/NKtpVZAOzAnBfLu4NnHwLyilZF4dGhhRB3EsIh8UHuj71oAAAC7SURBVEjHpczFAcMwAATBCMwYNtuh67/EPPXXTgFz8ieqw8OOB4OHEQ93PNieDkrwMOPhgYcspYMqPBR4sCkdZPCQ40E7HhwelODB4UE1HnI8yODhfNBBDg8yeLAtHXT1dNCjp4NKPGjCgz540IgHDT0d9PJ00DWhg6yhg+QOOui80kG6N3SQio4OyoYudgjeNR2k2+LhIFm3+cghyJ5LGzsEl8I0aewQ3MpvlaSxQ3DJn+U0r1v1q5v2Dz8YYfI+rH0RAAAAAElFTkSuQmCC');
    }

    &.no-text {
        height: 3vmin;
        padding: 2.5vmin;
        bottom: 0;
    }
}

img.image-hover-button--image {
    display: block;
    width: 100%;
    filter: grayscale(100%);
    transition: all ease-in-out 3000ms;
    object-fit: cover;
}
