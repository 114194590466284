.block.faq {
    border-bottom: 1px solid $dark-gray;
}

.faq--item {
    border-top: 1px solid $dark-gray;
    position: relative;

    &::after {
        display: block;
        content: url('/wp-content/themes/medaesthetics/assets/images/icon-caret-down.svg');
        width: 20px;
        position: absolute;
        top: 12px;
        right: 20px;
        pointer-events: none;
        transition: all ease-in-out 300ms;
    }

    &.faq--open {
        &::after {
            rotate: 180deg;
        }
    }

    @include media($tablet-down) {
    }

    .faq--question {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 0;
        cursor: pointer;
        transition: all ease-in-out 300ms;
        padding: 16px 40px 19px 5px;

        &:hover {
            color: $mute-pink;
        }

        @include media($tablet-down) {
            font-size: 16px;
            padding: 22px 40px 22px 5px;
        }
    }

    .faq--answer {
        height: 0;
        overflow: hidden;
        margin-bottom: 0;

        padding-left: 5px;
        padding-right: 5px;

        @include media($tablet-down) {
            font-size: 16px;
        }
    }
}
