.block.post-content {
    @include container;

    &.block_preview {
        .post-content--container {
            border: 1px dashed #bbb;

            > * {
                border: 1px dashed #bbb;
            }

            &::before {
                content: 'Basic Content';
                position: absolute;
                top: 6px;
                left: 10px;
                color: #ddd;
                font-size: 14px;
            }
        }
    }

    &.disable-padding {
        .post-content--container {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.top-margin-small {
        .post-content--container {
            padding-top: 3vmin;
        }
    }

    &.bottom-margin-small {
        .post-content--container {
            padding-bottom: 3vmin;
        }
    }

    &.top-margin-medium {
        .post-content--container {
            padding-top: 5vmin;
        }
    }

    &.bottom-margin-medium {
        .post-content--container {
            padding-bottom: 5vmin;
        }
    }

    &.top-margin-large {
        .post-content--container {
            padding-top: 8vmin;
        }
    }

    &.bottom-margin-large {
        .post-content--container {
            padding-bottom: 8vmin;
        }
    }

    &.inner-light-gray {
        .post-content--container {
            background-color: $light-gray;
        }
    }

    &.inner-gray {
        .post-content--container {
            background-color: $gray;
        }
    }

    &.inner-dark-gray {
        .post-content--container {
            background-color: $dark-gray;
        }
    }

    &.outer-light-gray {
        background-color: $light-gray;
    }

    &.outer-gray {
        background-color: $gray;
    }

    &.outer-dark {
        background-color: $charcoal;
        color: $white;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $white;
        }

        .post-content--container {
            background: linear-gradient(
                180deg,
                rgba(90, 86, 86, 1) 0%,
                $charcoal 80%
            );
        }
    }

    &.full-width {
        .post-content--container {
            > * {
                max-width: none;
            }
        }
    }

    .post-content--container {
        @include inner-center;
        position: relative;

        > * {
            max-width: 700px;
        }
    }
}
