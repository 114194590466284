.block.pullquote {
    @include container;
    padding: 8vmin 0 7vmin;
    background-color: $medium-pink;

    .pullquote--container {
        @include inner-center;
        color: $white;
    }

    &.block_preview {
        padding: 4vmin 0 3vmin;
    }
}

.pullquote--quote {
    display: block;
    font-size: 3vmin;
    line-height: 1.3;

    .block_preview & {
        font-size: 2vmin;
    }
}

.pullquote--citation {
    display: block;
    font-size: 2.1vmin;
    margin-top: 3.1vmin;

    @include media($tablet-down) {
        font-size: 4vmin;
    }
}
