.block.small-header {
    width: 100%;
    background-color: $off-white;
    padding: 10vmin 0 10vmin;

    overflow: hidden;

    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;

    &.block_preview {
        padding: 3vmin 0 3vmin;

        .small-header--title {
            font-size: 4vmin;
            padding-left: 20vmin;
        }
    }
}

.small-header--background {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;

    pointer-events: none;

    z-index: 10;
}

.small-header--title {
    position: relative;
    font-size: 5vmin;
    font-weight: 300;
    letter-spacing: -1.5px;
    color: #585454;
    opacity: 0.85;

    line-height: 1;
    padding-left: 25vmin;

    z-index: 20;

    @include media($tablet-down) {
        position: relative;
    }
}

.small-header--subtitle {
    color: $dark-pink;
}
