.block.staff-list {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    justify-content: start;
    justify-items: start;

    @include media($tablet-down) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include media($mobile-down) {
        grid-template-columns: 1fr 1fr;
    }
}

.tease-staff {
    padding-top: 0;
    position: relative;
    overflow: hidden;
    &:hover {
        .tease-staff--thumbnail {
            filter: grayscale(0%);
        }
        .tease-staff--meta {
            translate: 0 0;
        }
    }

    &.block_preview {
        .tease-staff--icon {
            display: none !important;
        }
    }
}

.tease-staff--meta {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 3vmin 2vmin 2vmin 2vmin;
    z-index: 10;
    background-color: $white;

    translate: 0 100%;
    transition: all 300ms ease-in-out;

    @include media($tablet-down) {
        position: relative;
        translate: 0 0;
    }
}

.tease-staff--title {
    font-size: 2.2vmin;
    margin-bottom: 0;

    @include media($tablet-down) {
        font-size: 16px;
    }
}

.tease-staff--qualifications {
    font-size: 1.6vmin;

    @include media($tablet-down) {
        font-size: 14px;
    }
}

.tease-staff--icon {
    max-width: 20px;
    max-height: 25px;
    margin-top: 10px;

    .block_preview & {
        display: none !important;
    }
}

.tease-staff--thumbnail {
    display: block;
    filter: grayscale(100%);
    transition: all ease-in-out 300ms;
}
