.block.simple-title {
    @include container;
    padding: 2.5vmin 0 3vmin;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(235, 235, 235, 1) 50%,
        rgba(255, 255, 255, 1) 100%
    );

    @include media($tablet-down) {
        padding: 12px 0 15px;
    }

    .simple-title--container {
        @include inner-center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

.simple-title--title {
    display: block;
    font-size: 3vmin;
    line-height: 1.2;
    margin-bottom: 0;

    @include media($tablet-down) {
        font-size: 20px;
    }
}
