.block.promotion {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: stretch;

    transition: all ease-in-out 300ms;
    width: 100%;
    max-width: 56vmin;
    color: $white;

    &:hover {
        opacity: 0.8;
    }

    &.background-gold {
        background: linear-gradient(
            90deg,
            rgba(170, 138, 56, 1) 0%,
            rgba(182, 150, 68, 0.2) 40%,
            rgba(171, 141, 80, 1) 100%
        );

        .promotion--footer {
            background-color: #977027;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background: linear-gradient(
                    90deg,
                    rgba(170, 138, 56, 1) 40%,
                    rgba(182, 150, 68, 0.7) 70%,
                    rgba(171, 141, 80, 0) 100%
                );
                z-index: 5;
            }
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(
                90deg,
                rgba(170, 138, 56, 1) 40%,
                rgba(182, 150, 68, 0.7) 70%,
                rgba(171, 141, 80, 0) 100%
            );
            z-index: 5;
        }
    }

    &.background-black {
        background: linear-gradient(
            90deg,
            rgba(#525050, 1) 0%,
            rgba(#525050, 0.2) 40%,
            rgba(#6d6968, 1) 100%
        );

        .promotion--footer {
            background-color: #484445;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background: linear-gradient(
                    90deg,
                    #525050 60%,
                    rgba(82, 80, 80, 0.7) 90%,
                    rgba(82, 80, 80, 0) 100%
                );
                z-index: 5;
            }
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(
                90deg,
                #525050 60%,
                rgba(82, 80, 80, 0.7) 90%,
                rgba(82, 80, 80, 0) 100%
            );
            z-index: 5;
        }
    }
}

.promotion--deal {
    display: flex;
    align-items: flex-start;
    padding: 3vmin 4vmin;
    z-index: 10;

    .deal--left {
        display: inline-block;
        font-size: 7vmin;
        line-height: 1;
    }
    .deal--right {
        display: inline-block;
        font-size: 3.5vmin;
        margin-left: 1vmin;
        padding-top: 0.4vmin;
    }
}

.promotion--footer {
    display: flex;
    padding: 3vmin 4vmin 3vmin;
    align-items: center;
    justify-content: space-between;

    border-top-right-radius: 40px;

    z-index: 10;
}

.promotion--service {
    font-size: 2.2vmin;
    font-weight: 400;
    z-index: 12;
}

span.promotion--button.block.icon-button.border-light {
    font-size: 1.4vmin;
    z-index: 12;
    letter-spacing: 1.5px;

    &:hover {
        color: $white !important;
        border-color: $white !important;
    }
}

.promotion--conditions {
    margin-top: 2vmin;

    @include media($desktop-down) {
        padding-left: 3vmin;
        font-size: 14px;
    }
}
