.block.faq-categories {
    display: flex;
    flex-direction: row;

    @include media($tablet-down) {
        flex-direction: column;
    }

    .faq-category {
        display: block;
    }

    .faq-categories--selector {
        width: 240px;
        padding-right: 20px;
        flex-shrink: 0;
        position: relative;

        @include media($tablet-down) {
            width: 100%;
            border: 1px solid $dark-gray;
            border-top: none;
            cursor: pointer;

            top: 10px;
            background-color: $white;
            z-index: 10;
            margin-bottom: 50px;

            &::after {
                display: block;
                content: url('/wp-content/themes/medaesthetics/assets/images/icon-caret-down.svg');
                width: 20px;
                position: absolute;
                top: 12px;
                right: 20px;
                pointer-events: none;
                transition: all ease-in-out 300ms;
            }

            &.selector--open {
                &::after {
                    rotate: 180deg;
                }

                .faq-categories--selector--item {
                    display: block;
                }
            }
        }
    }

    .faq-categories--selector--heading {
        margin-bottom: 10px;
        font-size: 22px;
        font-weight: 400;

        @include media($tablet-down) {
            display: none;
        }
    }

    .faq-categories--selector--item {
        display: block;
        border: none;
        padding: 10px 0;
        font-size: 18px;
        font-weight: 400;

        transition: all ease-in-out 300ms;

        &:hover,
        &.active {
            color: $mute-pink;
        }

        @include media($tablet-down) {
            font-size: 16px;
            padding: 15px 15px;
            border-top: 1px solid $dark-gray;
            display: none;

            &.active {
                display: block;
                color: $black;
            }

            &:hover {
                color: $mute-pink;
            }
        }
    }

    .faq-categories--categories {
        flex-basis: 80%;
        padding-top: 50px;

        @include media($tablet-down) {
            padding-top: 10px;
        }
    }

    .faq-categories--category {
        display: none;
        scroll-margin-top: 80px;

        &:target {
            display: block;
        }

        @include media($tablet-down) {
            scroll-margin-top: 60px;
        }
    }
}
